import { template as template_ef9abe6f5e1d4d039c50fa24bea93f82 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ef9abe6f5e1d4d039c50fa24bea93f82(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
