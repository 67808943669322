import { template as template_a920badc90384a7fa7b3fb1eb8eb67b0 } from "@ember/template-compiler";
const WelcomeHeader = template_a920badc90384a7fa7b3fb1eb8eb67b0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
