import { template as template_8d846af5cb0943e8b04c44b943512543 } from "@ember/template-compiler";
const FKLabel = template_8d846af5cb0943e8b04c44b943512543(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
